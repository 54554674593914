import { type ReactElement, useEffect, useState } from 'react';
import InputWrapper from '~/components/InputWrapper';
import { LucideAtSign } from 'lucide-react';

export default function EmailInput({
  placeholder = '',
  name = '',
  className = '',
  disabled,
  defaultValue = '',
  onChange = e => {},
}: {
  className?: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
}): ReactElement {
  const [email, setEmail] = useState<string>(defaultValue || '');
  const changeHandler = (e): void => {
    setEmail(e.target.value as string);
    if (onChange) {
      onChange(e);
    }
  };
  useEffect(() => {
    setEmail(defaultValue);
  }, [defaultValue]);

  return (
    <div className={'flex flex-col w-full justify-end'}>
      <InputWrapper
        type="text"
        placeholder={placeholder}
        name={name}
        className={className}
        disabled={disabled}
        defaultValue={email || ''}
        onChange={changeHandler}
        startAdornment={<LucideAtSign className="h-4 w-4" />}
      ></InputWrapper>
    </div>
  );
}
