import { type ReactElement, useState } from 'react';
import { LucideLanguages } from 'lucide-react';

interface LanguageOption {
  value: string;
  label: string;
}

interface LanguageSelectProps {
  defaultLanguage?: string;
  onSelectLanguage?: (language: string) => void;
  className?: string;
  name?: string;
}

// Using the top 40 languages by # of speakers in the USA
const languageOptions: LanguageOption[] = [
  { value: 'eng', label: 'English' },
  { value: 'spa', label: 'Spanish' },
  { value: 'zho', label: 'Chinese' },
  { value: 'tgl', label: 'Tagalog' },
  { value: 'vie', label: 'Vietnamese' },
  { value: 'ara', label: 'Arabic' },
  { value: 'kor', label: 'Korean' },
  { value: 'hmn', label: 'Hmong' },
  { value: 'rus', label: 'Russian' },
  { value: 'fra', label: 'French' },
  { value: 'deu', label: 'German' },
  { value: 'guj', label: 'Gujarati' },
  { value: 'hau', label: 'Hausa' },
  { value: 'ben', label: 'Bengali' },
  { value: 'pan', label: 'Punjabi' },
  { value: 'hin', label: 'Hindi' },
  { value: 'por', label: 'Portuguese' },
  { value: 'tel', label: 'Telugu' },
  { value: 'jpn', label: 'Japanese' },
  { value: 'nep', label: 'Nepali' },
  { value: 'swe', label: 'Swedish' },
  { value: 'amh', label: 'Amharic' },
  { value: 'yid', label: 'Yiddish' },
  { value: 'ita', label: 'Italian' },
  { value: 'pol', label: 'Polish' },
  { value: 'tam', label: 'Tamil' },
  { value: 'ukr', label: 'Ukrainian' },
  { value: 'hye', label: 'Armenian' },
  { value: 'mon', label: 'Mongolian' },
  { value: 'mkd', label: 'Macedonian' },
  { value: 'khm', label: 'Khmer' },
  { value: 'tha', label: 'Thai' },
  { value: 'nld', label: 'Dutch' },
  { value: 'tur', label: 'Turkish' },
  { value: 'ell', label: 'Greek' },
  { value: 'syr', label: 'Syriac' },
  { value: 'bos', label: 'Bosnian' },
];

export const getLanguageFromCode = (code: string): LanguageOption | null => {
  return languageOptions.find(option => option.value === code) ?? null;
};

const LanguageSelector = ({
  defaultLanguage = 'eng',
  onSelectLanguage,
  className,
  name = 'language6392Code',
}: LanguageSelectProps): ReactElement => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const language = event.target.value;
    setSelectedLanguage(language);
    onSelectLanguage && onSelectLanguage(language);
  };

  return (
    <div className="relative flex items-center justify-center mb-1">
      <LucideLanguages className="w-4 h-4 absolute left-3" />
      <select value={selectedLanguage} onChange={handleChange} className={className} name={name}>
        {languageOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
